export function extractColumnsTranslations(columns, intl) {
  const title = intl.formatMessage({
    id: `${columns}.title`,
  })
  const hasTitle = title !== 'null'
  const description = intl.formatMessage({
    id: `${columns}.description.description`,
  })
  const hasDescription = description !== 'null'
  const isRichText =
    intl.formatMessage({
      id: `${columns}.description.richText`,
    }) !== 'null'

  const buttons = intl.formatMessage({
    id: `${columns}.buttons.number`,
  })
  const number = intl.formatMessage({
    id: `${columns}.number`,
  })
  const totalColumns = intl.formatMessage({
    id: `${columns}.columns`,
  })

  const backgroundImage = intl.formatMessage({
    id: `${columns}.backgroundImage`,
  })
  return {
    title,
    hasTitle,
    description,
    hasDescription,
    isRichText,
    buttons,
    number,
    totalColumns,
    backgroundImage,
  }
}

export function extractColumnTranslations(column, intl) {
  const columnTitle = intl.formatMessage({
    id: `${column}.title`,
  })
  const hasColumnTitle = columnTitle !== 'null'
  const columnDescription = intl.formatMessage({
    id: `${column}.description.description`,
  })
  const hasColumnDescription = columnDescription !== 'null'
  const isColumnRichText =
    intl.formatMessage({
      id: `${column}.description.richText`,
    }) !== 'null'

  const columnButtons = intl.formatMessage({
    id: `${column}.buttons.number`,
  })

  const columnImages = intl.formatMessage({
    id: `${column}.images.number`,
  })

  const key = intl.formatMessage({
    id: `${column}.data.key`,
  })
  const unit = intl.formatMessage({
    id: `${column}.data.unit`,
  })
  const hasKey = key !== 'null'
  return {
    columnTitle,
    hasColumnTitle,
    columnDescription,
    hasColumnDescription,
    isColumnRichText,
    columnButtons,
    columnImages,
    key,
    unit,
    hasKey,
  }
}
