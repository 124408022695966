import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH3,
  StyledSpan,
  StyledUnderlinedH2,
} from './styles'
import {
  extractColumnTranslations,
  extractColumnsTranslations,
} from './helpers'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Button from '../reusables/button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledImageContainer = styled(Box)`
  height: 125px;
`
const StyledCoinH3 = styled(StyledH3)`
  color: ${props => props.theme.global.colors.primaryBlack};
`
const StyledCoinSpan = styled(StyledSpan)`
  text-transform: uppercase;
`

const StyledContentContainer = styled(Box)``

const Coins = ({ index, data, shade, intl }) => {
  const columns = `coins.coins${index}`
  const {
    title,
    hasTitle,
    description,
    hasDescription,
    isRichText,
    buttons,
    number,
    totalColumns,
    backgroundImage,
  } = extractColumnsTranslations(columns, intl)
  return (
    <StyledContainer
      componentName="Coins"
      shade={shade}
      data={data}
      backgroundImage={backgroundImage}
    >
      <StyledContentContainer align="center" justify="center" width="full">
        {hasTitle && <StyledUnderlinedH2>{title}</StyledUnderlinedH2>}
        {hasDescription && !isRichText ? (
          <StyledSpan>{description}</StyledSpan>
        ) : (
          hasDescription &&
          isRichText && (
            <StyledSpan dangerouslySetInnerHTML={{ __html: description }} />
          )
        )}

        <StyledColumnsContainer
          columns={totalColumns}
          align="center"
          justify="center"
        >
          {Array.from(Array(Number(number)).keys()).map((i, index) => {
            const column = `${columns}.column${index}`
            const {
              columnTitle,
              hasColumnTitle,
              columnDescription,
              hasColumnDescription,
              isColumnRichText,
              columnButtons,
              columnImages,
              key,
              unit,
              hasKey,
            } = extractColumnTranslations(column, intl)

            return (
              <StyledColumnContainer
                align="start"
                key={shortid.generate()}
                justify="center"
                margin={{ top: '70px' }}
              >
                {columnImages > 0 && (
                  <Box
                    direction="row-responsive"
                    gap="medium"
                    pad={{ vertical: 'medium' }}
                  >
                    {Array.from(Array(Number(columnImages)).keys()).map(i => {
                      const columnImage = `${section}.images.image${i}`

                      const imageLink = intl.formatMessage({
                        id: `${columnImage}.link`,
                      })
                      const hasLink = imageLink !== 'null'

                      const gif = intl.formatMessage({
                        id: `${columnImage}.gif`,
                      })
                      const isGif = gif !== 'null'
                      const iconPath = intl.formatMessage({
                        id: `${columnImage}.path`,
                      })
                      let imagePath = 'imagePath'
                      if (!isGif) {
                        imagePath = data[`${iconPath}`].childImageSharp.fixed
                      } else {
                        imagePath = require(`./gifs/${iconPath}.gif`)
                      }
                      return (
                        <StyledImageContainer key={shortid.generate()}>
                          {hasLink ? (
                            <a href={imageLink} target="_blank">
                              {isGif ? (
                                <StyledImageContainer>
                                  <img src={imagePath} />
                                </StyledImageContainer>
                              ) : (
                                <StyledImageContainer>
                                  <Img
                                    imgStyle={{ objectFit: 'contain' }}
                                    fixed={imagePath}
                                  />
                                </StyledImageContainer>
                              )}
                            </a>
                          ) : isGif ? (
                            <StyledImageContainer>
                              <img src={imagePath} />
                            </StyledImageContainer>
                          ) : (
                            <StyledImageContainer>
                              <Img
                                imgStyle={{ objectFit: 'contain' }}
                                fixed={imagePath}
                              />
                            </StyledImageContainer>
                          )}
                        </StyledImageContainer>
                      )
                    })}
                  </Box>
                )}
                {hasColumnTitle && (
                  <StyledCoinSpan>{columnTitle}</StyledCoinSpan>
                )}
                {hasColumnDescription && !isColumnRichText ? (
                  <StyledCoinH3>{columnDescription}</StyledCoinH3>
                ) : (
                  hasColumnDescription &&
                  isColumnRichText && (
                    <StyledCoinH3
                      dangerouslySetInnerHTML={{ __html: columnDescription }}
                    />
                  )
                )}
                {hasKey &&
                  data.allCoins.edges.map(coin => (
                    <StyledCoinH3>{`${coin.node[key]} ${unit}`}</StyledCoinH3>
                  ))}
                {columnButtons > 0 && (
                  <Box
                    direction="row-responsive"
                    gap="medium"
                    pad={{ vertical: 'medium' }}
                  >
                    {Array.from(Array(Number(columnButtons)).keys()).map(i => {
                      const columnButton = `button${i}`
                      const columnButtonLabel = intl.formatMessage({
                        id: `${section}.buttons.${columnButton}.label`,
                      })
                      const columnButtonLink = intl.formatMessage({
                        id: `${section}.buttons.${columnButton}.link`,
                      })
                      return (
                        <Button
                          key={shortid.generate()}
                          label={columnButtonLabel}
                          link={columnButtonLink}
                          light={
                            shade === 'light' || shade === 'grey' ? true : false
                          }
                        />
                      )
                    })}
                  </Box>
                )}
              </StyledColumnContainer>
            )
          })}
        </StyledColumnsContainer>
        {buttons > 0 && (
          <Box
            direction="row-responsive"
            gap="medium"
            pad={{ vertical: 'medium' }}
          >
            {Array.from(Array(Number(buttons)).keys()).map(i => {
              const button = `button${i}`
              const buttonLabel = intl.formatMessage({
                id: `${columns}.buttons.${button}.label`,
              })
              const buttonLink = intl.formatMessage({
                id: `${columns}.buttons.${button}.link`,
              })
              return (
                <Button
                  key={shortid.generate()}
                  label={buttonLabel}
                  link={buttonLink}
                  light={shade === 'light' || shade === 'grey' ? true : false}
                />
              )
            })}
          </Box>
        )}
      </StyledContentContainer>
    </StyledContainer>
  )
}

Coins.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(Coins)
