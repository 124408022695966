import { Link, withIntl } from '../../i18n'
import { StaticQuery, graphql } from 'gatsby'
import { bannerIcon, fourColumns, logos } from '../../fragments/fragments'

import Banner from '../../components/reusables/banner'
import Benefits from '../../components/reusables/benefits'
import { Box } from 'grommet'
import Boxes from '../../components/reusables/boxes'
import Centered from '../../components/reusables/centered'
import Coins from '../../components/reusables/coins'
import Layout from '../../components/layout'
import React from 'react'

const Overview = ({ data }) => (
  <Layout>
    <Banner data={data} shade={'light'} />
    <Benefits index={0} data={data} shade={'light'} />
    <Centered data={data} shade={'grey'} index="0" />
    {/* <Box pad="xlarge" background="light-1">
      <div
        className="coinmarketcap-currency-widget"
        data-currencyid="2246"
        data-base="USD"
        data-secondary="BTC"
        data-ticker="true"
        data-rank="true"
        data-marketcap="true"
        data-volume="true"
        data-stats="USD"
        data-statsticker="false"
      />
    </Box> */}
    <Coins data={data} shade={'light'} index="0" />
    <Boxes index={0} data={data} shade={'grey'} />
  </Layout>
)

export default withIntl(Overview)

export const imagesQuery = graphql`
  query {
    allCoins {
      edges {
        node {
          id
          name
          symbol
          price
          volume
          market_cap
        }
      }
    }
    lastSection: file(relativePath: { eq: "hero/lastSection@2x.png" }) {
      childImageSharp {
        fluid(maxHeight: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bannerCoin: file(relativePath: { eq: "hero/bannerCoin@3x.png" }) {
      ...bannerIcon
    }
    fast: file(relativePath: { eq: "regular/fast@3x.png" }) {
      ...fourColumns
    }
    free: file(relativePath: { eq: "regular/free@3x.png" }) {
      ...fourColumns
    }
    safe: file(relativePath: { eq: "regular/safe@3x.png" }) {
      ...fourColumns
    }
    smart: file(relativePath: { eq: "regular/smart@3x.png" }) {
      ...fourColumns
    }
    fivemiles: file(relativePath: { eq: "logos/fivemiles@3x.png" }) {
      ...logos
    }
    cobinhood: file(relativePath: { eq: "logos/cobinhood@3x.png" }) {
      ...logos
    }
    binance: file(relativePath: { eq: "logos/binance@3x.png" }) {
      ...logos
    }
    lbank: file(relativePath: { eq: "logos/lbank@3x.png" }) {
      ...logos
    }
    huobi: file(relativePath: { eq: "logos/huobi@3x.png" }) {
      ...logos
    }
    coinbene: file(relativePath: { eq: "logos/coinbene@3x.png" }) {
      ...logos
    }
    dragonex: file(relativePath: { eq: "logos/dragonex@3x.png" }) {
      ...logos
    }
    bibox: file(relativePath: { eq: "logos/bibox@3x.png" }) {
      ...logos
    }
    koinex: file(relativePath: { eq: "logos/koinex@3x.png" }) {
      ...logos
    }
    okex: file(relativePath: { eq: "logos/okex@3x.png" }) {
      ...logos
    }
    coincoming: file(relativePath: { eq: "logos/coincoming@3x.png" }) {
      ...logos
    }
    bithumb: file(relativePath: { eq: "logos/bithumb@3x.png" }) {
      ...logos
    }
    changenow: file(relativePath: { eq: "logos/changenow@3x.jpg" }) {
      ...logos
    }
  }
`
