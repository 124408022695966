import {
  ColumnImages,
  extractColumnTranslations,
  extractColumnsTranslations,
} from './helpers'
import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH3,
  StyledP,
  StyledUnderlinedH2,
} from './styles'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Button from '../reusables/button'
import Img from 'gatsby-image'
import Link from '../reusables/link'
import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledImageContainer = styled(Box)`
  width: 190px;
`
const StyledContactColumnContainer = styled(StyledColumnContainer)`
  display: grid;
  grid-auto-rows: 190px 60px;
  font-size: 14px;
  justify-items: center;
  text-align: center;
`

const StyledContactHeader = styled(StyledH3)`
  color: ${props => props.theme.global.colors.primaryBlack};
  text-transform: none;
`

const StyledContactP = styled(StyledP)`
  font-size: 14px;
`

const StyledContentContainer = styled(Box)``

const Benefits = ({ index, data, shade, intl }) => {
  const columns = `benefits.columns${index}`
  const {
    title,
    hasTitle,
    description,
    hasDescription,
    isRichText,
    buttons,
    number,
    totalColumns,
    backgroundImage,
  } = extractColumnsTranslations(columns, intl)

  return (
    <StyledContainer
      componentName="Columns"
      shade={shade}
      data={data}
      backgroundImage={backgroundImage}
    >
      <StyledContentContainer align="center" justify="center" width="full">
        {hasTitle && <StyledUnderlinedH2>{title}</StyledUnderlinedH2>}
        {hasDescription && !isRichText ? (
          <StyledP>{description}</StyledP>
        ) : (
          hasDescription &&
          isRichText && (
            <StyledSpan dangerouslySetInnerHTML={{ __html: description }} />
          )
        )}

        <StyledColumnsContainer
          columns={totalColumns}
          align="center"
          justify="center"
        >
          {Array.from(Array(Number(number)).keys()).map((i, index) => {
            const column = `${columns}.column${index}`
            const {
              columnTitle,
              hasColumnTitle,
              columnDescription,
              hasColumnDescription,
              isColumnRichText,
              columnButtons,
              columnImages,
            } = extractColumnTranslations(column, intl)

            return (
              <StyledContactColumnContainer
                key={shortid.generate()}
                width={"295px"}
              >
                {columnImages > 0 &&
                  Array.from(Array(Number(columnImages)).keys()).map(i => {
                    const columnImage = `${column}.images.image${i}`

                    const imageLink = intl.formatMessage({
                      id: `${columnImage}.link`,
                    })
                    const hasLink = imageLink !== 'null'

                    const gif = intl.formatMessage({
                      id: `${columnImage}.gif`,
                    })
                    const isGif = gif !== 'null'
                    const iconPath = intl.formatMessage({
                      id: `${columnImage}.path`,
                    })
                    let imagePath = 'imagePath'
                    if (!isGif) {
                      imagePath = data[`${iconPath}`].childImageSharp.fixed
                    } else {
                      imagePath = require(`./gifs/${iconPath}.gif`)
                    }
                    return (
                      <StyledImageContainer key={shortid.generate()}>
                        {hasLink ? (
                          <a href={imageLink} target="_blank">
                            {isGif ? (
                              <StyledImageContainer>
                                <img alt={iconPath} src={imagePath} />
                              </StyledImageContainer>
                            ) : (
                              <StyledImageContainer>
                                <Img
                                  alt={iconPath}
                                  imgStyle={{ objectFit: 'contain' }}
                                  fixed={imagePath}
                                />
                              </StyledImageContainer>
                            )}
                          </a>
                        ) : isGif ? (
                          <StyledImageContainer>
                            <img alt={iconPath} src={imagePath} />
                          </StyledImageContainer>
                        ) : (
                          <StyledImageContainer>
                            <Img
                              alt={iconPath}
                              imgStyle={{ objectFit: 'contain' }}
                              fixed={imagePath}
                            />
                          </StyledImageContainer>
                        )}
                      </StyledImageContainer>
                    )
                  })}
                {hasColumnTitle && (
                  <StyledContactHeader>{columnTitle}</StyledContactHeader>
                )}
                {hasColumnDescription && !isColumnRichText ? (
                  <StyledContactP>{columnDescription}</StyledContactP>
                ) : (
                  hasColumnDescription &&
                  isColumnRichText && (
                    <StyledContactP
                      dangerouslySetInnerHTML={{ __html: columnDescription }}
                    />
                  )
                )}
                {columnButtons > 0 && (
                  <Box
                    direction="row-responsive"
                    gap="medium"
                    pad={{ vertical: 'medium' }}
                  >
                    {Array.from(Array(Number(columnButtons)).keys()).map(i => {
                      const columnButton = `button${i}`
                      const columnButtonLabel = intl.formatMessage({
                        id: `${section}.buttons.${columnButton}.label`,
                      })
                      const columnButtonLink = intl.formatMessage({
                        id: `${section}.buttons.${columnButton}.link`,
                      })
                      return (
                        <Link
                          key={shortid.generate()}
                          label={columnButtonLabel}
                          link={columnButtonLink}
                          light={
                            shade === 'light' || shade === 'grey' ? false : true
                          }
                        />
                      )
                    })}
                  </Box>
                )}
              </StyledContactColumnContainer>
            )
          })}
        </StyledColumnsContainer>
        {buttons > 0 && (
          <Box
            direction="row-responsive"
            gap="medium"
            pad={{ vertical: 'medium' }}
          >
            {Array.from(Array(Number(buttons)).keys()).map(i => {
              const button = `button${i}`
              const buttonLabel = intl.formatMessage({
                id: `${columns}.buttons.${button}.label`,
              })
              const buttonLink = intl.formatMessage({
                id: `${columns}.buttons.${button}.link`,
              })
              return (
                <Button
                  key={shortid.generate()}
                  label={buttonLabel}
                  link={buttonLink}
                  light={shade === 'light' || shade === 'grey' ? true : false}
                />
              )
            })}
          </Box>
        )}
      </StyledContentContainer>
    </StyledContainer>
  )
}

Benefits.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(Benefits)
